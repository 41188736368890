<template>
  <BModal
    id="modalDetail"
    ref="modalDetail"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
    @shown="getDetailDevice()"
  >
    <div class="text-left my-1 d-flex flex-column gap-8 text-black">
      <div class="font-semibold text-2xl text-black mb-1">
        Device
      </div>
      <div class="d-flex">
        <div class="text-8 col p-0"><strong>Nama device:</strong></div>
        <div class="text-8 col p-0">{{detailDevice.device_name}}</div>
      </div>
      <div class="d-flex">
        <div class="text-8 col p-0"><strong>Spesifikasi:</strong></div>
        <div class="text-8 col p-0">{{detailDevice.specification}}</div>
      </div>
      <div class="d-flex">
        <div class="text-8 col p-0"><strong>Tipe: </strong></div>
        <div class="text-8 col p-0">{{detailDevice.device_type}}</div>
      </div>
      <div class="d-flex">
        <div class="text-8 col p-0"><strong>Partner:</strong></div>
        <div class="text-8 col p-0">{{detailDevice.partner_name}}</div>
      </div>
      <div class="d-flex">
        <div class="text-8 col p-0"><strong>Tanggal diterima:</strong></div>
        <div class="text-8 col p-0">{{DAY_MONTH_YEAR(detailDevice.device_pick_date)}}</div>
      </div>
      <div class="d-flex">
        <div class="text-8 col p-0"><strong>Attachment:</strong></div>
      </div>
      <div class="d-flex mt-1">
        <div class="shadow image-area d-flex justify-content-center align-items-center overflow-scroll">
          <span v-if="!detailDevice.attachment">Tidak ada gambar ditampilkan</span>
          <img :src="detailDevice.attachment" class="h-100" alt="device" v-else>
        </div>
      </div>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mt-2 w-100"
          @click="$bvModal.hide('modalDetail')"
        >
          Oke
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

export default {
  name: 'modalDetail',
  props: {
    idItem: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      alertError,
      detailDevice: {},
      DAY_MONTH_YEAR,
    }
  },
  methods: {
    async getDetailDevice() {
      const url = `v1/devices/${this.idItem}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailDevice = await data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>

<style scoped>
.image-area {
  width: 460px;
  height: 230px;
}
</style>
