// eslint-disable-next-line import/prefer-default-export
import {
  firstDateOfMonth, today, last7, last2,
} from '@/store/helpers'

export const tableBiodata = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'device_name',
    label: 'Nama Device',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'partner_name',
    label: 'Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'device_holder_name',
    label: 'Pemegang Device',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'device_type',
    label: 'Tipe Device',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '42px !important',
      width: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-1',
  },
]

export const dateRange = {
  startDate: '',
  endDate: '',
}

export const locale = {
  format: 'dd/mm/yyyy',
  daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
}

export const ranges = {
  'Hari ini': [today, today],
  '2 Hari Terakhir': [last2, today],
  '7 Hari Terakhir': [last7, today],
  'Bulan Ini': [firstDateOfMonth, today],
}

export const listType = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: 'Handphone',
    value: 'handphone',
  },
  {
    label: 'Laptop',
    value: 'laptop',
  },
  {
    label: 'Lain-lain',
    value: 'lain-lain',
  },
]
